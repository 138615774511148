import { GridRenderCellParams } from '@mui/x-data-grid';
import { ReactNode } from 'react';

export enum scheduleStatus {
  'Card',
  'Pin only',
  'Card and pin',
  'Card or pin',
  'Facility code only',
  'Unlock',
  'Threat lock down',
  'Card and pin no grant on duress',
}

export type schedulesDataType = {
  mode: string;
  mode_name?: string;
  minutes: number;
  schedule: Date;
  schedule_date?: string;
  schedule_end_date?: string;
  readers_name: string;
  readers_key: string;
  floor?: string;
  task_id: string;
  timeLeft?: number;
  days_of_week?: string[];
};

export type ScheduleColumnType = {
  field: string;
  headerName: string;
  flex: number;
  renderCell?: (params: GridRenderCellParams) => ReactNode;
};

export enum scheduleModeEnum {
  end_time = 'end_time',
  minute = 'minute',
  hour = 'hour',
}

export enum HolidayDurationModeEnum {
  twelve_hour = 12,
  twenty_four_hour = 24,
}

export enum HolidayTriggerDayModeEnum {
  today = 'today',
  tomorrow = 'tomorrow',
  customRang = 'customRange'
}

export enum HolidayTriggerTimeModeEnum {
  rightNow = 'rightNow',
  midnight = 'midnight',
}

export enum DaysOfWeek {
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
}

export type ScheduleBaseType = {
  // need for get request payload for all readers
  mode: string;
  minutes: number;
  floor: number | null;
  days_of_week: string[] | null;
  is_from_holiday_schedule: boolean;
  is_from_mode_all_schedule: boolean;
  is_send_now_required: boolean;
};

export type ScheduleBaseRequestType = ScheduleBaseType & {
  init_date: number;
}

export type ScheduleRequestType = ScheduleBaseRequestType & {
  // need for get request payload
  reader_key: string;
};

export type SetSchedulesRequestType = {
  // need for set list schedules for create schedules
  schedule_list: ScheduleRequestType[];
};

export type ScheduleMainType = ScheduleBaseType & {
  reader_key: string;
}

export type ScheduleMainDataType = ScheduleMainType & {
  // need for get data about schedule
  reader_name: string;

  instance_id: string;
  server_id: string;
  platform: string; //TODO check

  init_date: string;
  end_date: string;
  task_start_id: string;
};

export type ScheduleFullDataType = ScheduleMainDataType & {
  // need for show data about schedule if front
  schedule_visible_init_date: string;
  schedule_visible_end_date: string;
  schedule_time_left: number;
  type: string;
};

export type ScheduleResponseType = {
  // need for get schedule list
  active_schedule_list: ScheduleMainDataType[];
  currently_schedule_list: ScheduleMainDataType[];
  hold_schedule_list: ScheduleMainDataType[];
};
